import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Link from '../components/Link';
import Copyright from '../components/Copyright';


export default function About() {
  return (
    <Container maxWidth="sm">
        <Box my={1} align="center">
            <Link to="/" title="Unitilator Home">
                <img alt="Logo" src="img/splash_logo.png"></img>
            </Link>
        </Box>

        <Box my={4} fontSize={16}>
            <Typography variant="h2" component="h2" gutterBottom>
                About Unit Prices
            </Typography>
            <p>Calculating the unit price of items allows for a price comparison between
                different size/quantity packaging combinations.
            </p>
            <p>Unit pricing provides a a way to determine which package size or quantity
                provides the best value.
            </p>
            <h2>Unit size</h2>
            <p>
                With many everyday items we might purchase, there is usually
                a choice when it comes to size &mdash; the large "bulk" pack of laundry powder
                vs a small pack; a regular size chocolate bar vs a pack of small "fun"
                size chocolate bars.
            </p>
            <p>
                As the concept of "buying in bulk" implies, usually
                the larger the pack, the better the value.   Though not always,
                and sometimes it's not at all obvious which size offers the best deal.
                Which is where unit pricing comes in.
            </p>
            <h2>Unit price</h2>
            <p>
                The most straightforward way to compare two different size items at
                different prices is to calculate what the equivalent price would be
                if they were both the same size.
            </p>
            <p>
                For example, let's say we want to compare bottled softdrinks,
                and we decide to use an arbitrary unit price of 1 Litre.
                If a 2 Litre bottle of softdrink at the local
                shops costs $2.00, then it's straightforward to determine that
                the unit price is $1 per Litre.  However, it's a little less
                straightforward to calculate what the unit price of a 1.25L
                bottle costing $2.20 would be ($1.76 per Litre) even if in
                this case it would be obvious that the 2L bottle is better value.
            </p>
            <h2>Calculating unit prices</h2>
            <p>Commonly used unit prices are 100g or 1kg for products sold by weight,
                and 100ml or 1L for drinks and other products sold by volume.
            </p>
            <p>
                Let's say we have a chocolate craving we want to indulge.
            </p>
            <p>
                The local supermarket is selling a 39g chocolate bar for $2.00.
                However, a 180g block of chocolate sells for $3.50.
                How can we work out which option gives us chocolate at the best price?
            </p>
            <p>
                Let's convert both to a unit price of 100g.
            </p>
            <p>2 &divide; 39 &times; 100 = <strong>$5.13 per 100g</strong></p>
            <p>3.5 &divide; 180 &times; 100 = <strong>$1.94 per 100g</strong></p>
            <p>
                So the larger 180g block of chocolate is better value at $1.94 per 100g (just don't eat it all at once!).
            </p>
            <p>
                The unit price of the 39g bar shows that we would be paying well over twice as much per unit of
                chocolate compared with the 180g block.
            </p>
        </Box>
        <Box py={4} fontSize={22}>
            <Link to="/">Home</Link>
            <Copyright />
        </Box>
    </Container>
  );
}
