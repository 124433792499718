import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="right">
      {'Copyright © ' + new Date().getFullYear() + ' '}
      <MuiLink color="inherit" href="http://www.nullific.com.au/"
               target="_blank"
               rel="noopener noreferrer">
        NULLIFIC
      </MuiLink>
    </Typography>
  );
}
